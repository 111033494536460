<template>
  <div>
    404
  </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>

</style>